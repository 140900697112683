.App {
  // width: 100vw;
  // min-height: 100vh;
  background-color: #2d2d2d;

  #container {
    position: relative;
    z-index: 1 !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    margin: auto;
    padding: 150px 0 20px 0;

    #main {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 90%;
      max-width: 1100px;
      z-index: 2;
      border-radius: 20px;
      overflow: hidden;
      margin-bottom: 50px;

      .panel {
        background-color: white;
      }
    }

    @media screen and (max-width: 992px) {
      padding: 100px 0 20px 0;
    }
  }
}
