.timeline {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .timeline-container {
    position: relative;
    display: flex;
    flex-direction: column;

    .timeline-item {
      position: relative;
      padding-left: 30px;
      padding-top: 30px;
      padding-bottom: 30px;
      border-left: 4px solid var(--timeline-color);

      .timeline-item__icon {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 32px;
        left: -22px;
        background-color: var(--timeline-color);
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;

        svg {
          color: white;
          font-size: 40px;
          padding: 8px 8px;
        }

        &::after {
          content: "";
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          top: 10px;
          right: -6px;
          border-left: 12px solid var(--timeline-color);
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
        }
      }
    }
  }
}
