.nav-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 65px;
  height: 65px;
  margin: 0 15px;

  .tag {
    position: absolute;
    display: block;
    top: -60px;
    width: 110px;
    font-weight: lighter;
    color: black;
    font-size: 16px;
    text-align: center;
    padding: 10px 0;
    background: #d2d2d2;
    transition: opacity 0.25s ease-in-out;
    border-radius: 5px;
    opacity: 0;

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -0.5em;
      left: 50%;
      margin-left: -0.6em;
      border-top: solid 0.6em #d2d2d2;
      border-left: solid 0.6em transparent;
      border-right: solid 0.6em transparent;
    }
  }

  .icon {
    // margin-top: 1em;
    position: relative;
    display: inline-block;
    color: white;
    line-height: 0.9em;
    outline: 0;
    transition: opacity 0.25s ease-in-out;

    svg {
      opacity: 0.35;
      font-size: 45px;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      bottom: -3em;
      margin-left: -2em;
      border-bottom: solid 0em white;
      border-left: solid 2em transparent;
      border-right: solid 2em transparent;
      transition: border-bottom-width 0.25s ease-in-out;

      @media screen and (max-width: 992px) {
        display: none;
      }
    }
  }

  &.active {
    .icon {
      transform-style: preserve-3d;

      svg {
        opacity: 1;
      }

      &:after {
        border-bottom-width: 2em;
        transform: translateZ(-1px);
      }
    }
  }

  &:hover {
    svg {
      opacity: 1;
    }

    .tag {
      opacity: 1;
    }
  }
}
