.home {
  width: 100%;
  display: flex;

  .row {
    @media screen and (max-width: 992px) {
      flex-direction: column-reverse;
    }
  }

  #intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4em 4em;

    @media screen and (max-width: 992px) {
      align-items: center;
      padding: 2em 1em;
    }

    h1 {
      font-size: 3.5em;
      font-weight: 400;
      margin-bottom: 0;
    }

    code {
      font-size: 24px;
    }

    p {
      letter-spacing: -0.015em;
      font-weight: 300;
      font-size: 2em;
      margin: 0.25em 0 0 0;
      color: #aaa;
    }

    @media screen and (max-width: 425px) {
      h1 {
        font-size: 2.5em;
      }

      p {
        font-size: 1.5em;
      }
    }
  }

  #cover {
    padding: 0;
    overflow: hidden;
    flex: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
