.footer {
  width: 100%;
  color: rgba(255, 255, 255, 0.45);
  text-align: center;
  font-size: 20px;

  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;

    li {
      display: inline-block;
      border-left: solid 1px rgba(255, 255, 255, 0.25);

      &:first-child {
        padding-left: 0;
        margin-left: 0;
        border-left: 0;
      }

      a {
        color: #ddd;
        color: rgba(255, 255, 255, 0.65);
        transition: color 0.25s ease-in-out;

        &:hover {
          color: white;
        }
      }
    }
  }
}
