.experiences {
  padding: 50px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 600px) {
    padding: 50px 0px;
  }
}
